/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import '../styles/main.css';
import MetaTags from 'react-meta-tags';

import i18n from '../../config/i18n';

const LocaleContext = React.createContext();

const Layout = ({ children, pageContext: { locale } }) => {
  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <MetaTags>
        <meta name="p:domain_verify" content="5418f7e97089b6cdeb07d1f8d84c5983" />
        <link rel="icon" href="/favicon.png" type="image/png" />
        <script
          type="text/javascript"
          async
          src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/283e1157-28bf-4293-9443-33d889059a03-loader.js"
        ></script>
      </MetaTags>
      <>{children}</>
    </LocaleContext.Provider>
  );
};

export { LocaleContext, Layout };

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};
