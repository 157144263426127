module.exports = {
  'pt-br': {
    default: true,
    path: 'pt',
    locale: 'pt-br',
    siteLanguage: 'pt',
    ogLang: 'pt_BR',
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
  },
  'es-es': {
    path: 'es',
    locale: 'es-es',
    siteLanguage: 'es',
    ogLang: 'es_ES',
  },
};
